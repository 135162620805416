import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import Iconify from "../../components/Iconofy/Iconofy";
import { getDocsTree } from "../../requests";
import more from "../../assets/icons/arrow-more.svg";
import less from "../../assets/icons/arrow-less.svg";
import { useNavigate, useParams } from "react-router-dom";
import { DOC_URL } from "../../navigation/routes";

const DrawerList = ({ onItemClick }) => {
  const navigate = useNavigate();
  const { lang, "*": slugPath } = useParams();

  const [openItems, setOpenItems] = useState({});
  const [nestedItems, setNestedItems] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getDocsTree();
      setNestedItems(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (slugPath) {
      const parentItem = nestedItems.find((item) =>
        item.children.some((child) => child.slugPath === slugPath)
      );
      if (parentItem && !openItems[parentItem.id]) {
        handleToggle(parentItem.id);
      }
    }
  }, [nestedItems, slugPath, openItems]);

  const handleToggle = (itemId) => {
    setOpenItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const handleClick = (slugPath) => {
    navigate(DOC_URL.replace(":lang", lang).replace("*", slugPath));
    onItemClick();
  };

  return (
    <div>
      <List>
        {nestedItems.map((item) => (
          <Fragment key={item.id}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleToggle(item.id)}>
                <ListItemIcon>
                  <Iconify icon={openItems[item.id] ? more : less} />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
            <Collapse in={openItems[item.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.children &&
                  item.children.map((child) => (
                    <ListItem key={child.id} disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={slugPath === child.slugPath}
                        onClick={() => handleClick(child.slugPath)}
                      >
                        <ListItemText primary={child.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
      <Divider />
    </div>
  );
};

export default DrawerList;
