import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Label from "../../../components/label";
import {
  convertTimeFromMillisecond,
  convertTimeFromUnix,
} from "../../../helpers/convertTime";
import {
  printJobsStatusColors,
  printJobsStatusLabels,
} from "../../../helpers/printJobsStatuses";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "bold",
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PrintJobInfoDialog = ({
  open,
  onClose,
  file_uri,
  id,
  created_at,
  expired_at,
  printer_name,
  status,
  computer_name,
  print_statuses,
}) => {
  const { t } = useTranslation();
  const [showLogs, setShowLogs] = useState(false);

  const handleShowLogs = () => {
    setShowLogs(!showLogs);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }} variant="h4">
        {t("common.print-job-dialog-title")}
        {id}
      </DialogTitle>
      <Divider sx={{ my: 2 }} />

      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  ID
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {id}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("common.status")}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Label color={printJobsStatusColors[status]}>
                    {t(printJobsStatusLabels[status])}
                  </Label>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("common.computer")}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {computer_name}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("common.printer")}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {printer_name}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("common.file-url")}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: "break-all" }}
                >
                  <Link
                    href={file_uri}
                    color="primary"
                    target="_blank"
                    rel="noopener"
                  >
                    {file_uri}
                  </Link>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("common.created-at")}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {convertTimeFromUnix(created_at)}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("common.expired-at")}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {convertTimeFromUnix(expired_at)}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {showLogs && (
        <>
          <Divider sx={{ my: 2 }} />
          <DialogContent>
            <Stack spacing={2} sx={{ ml: 1, mr: 1 }}>
              {print_statuses.map((status, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={index}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      fontWeight={"bold"}
                      sx={{ fontFamily: "Courier New, monospace" }}
                    >
                      {convertTimeFromMillisecond(status.createdAt)}
                    </Typography>
                  </Stack>
                  <Typography
                    fontWeight={"bold"}
                    sx={{ fontFamily: "Courier New, monospace" }}
                  >
                    {status.statusName}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </DialogContent>
        </>
      )}

      <Divider sx={{ my: 2 }} />

      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button variant="contained" color="primary" onClick={handleShowLogs}>
          {t("common.button-statuses")}
        </Button>
        <Button variant="contained" color="inherit" onClick={onClose}>
          {t("common.button-close")}
        </Button>
      </DialogActions>


    </Dialog>
  );
};

export default PrintJobInfoDialog;
