import { useCallback, useEffect, useState } from "react";
import { getDocsTree } from "../../requests";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DOC_URL } from "../../navigation/routes";
import useErrorHandling from "../../hooks/useErrorHandling";
import Head from "../../components/head/Head";
import { useTranslation } from "react-i18next";

const DocsPage = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();
  const [tree, setTree] = useState([]);

  const { setError } = useErrorHandling();

  const fetchData = useCallback(async () => {
    try {
      const response = await getDocsTree();
      setTree(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }, [setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      <Head title={"DOCS"} description={"desc"} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{t("docs-page.title")}</Typography>
        </Grid>
        <Grid item xs={12}>
          {tree.map((item) => (
            <div key={item.id}>
              <Typography variant="h5">{item.title}</Typography>
              {item.children &&
                item.children.map((child) => (
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    key={child.id}
                    sx={{ px: 3 }}
                  >
                    <Typography>•</Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        pl: 2,
                        cursor: "pointer",
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() =>
                        navigate(
                          DOC_URL.replace(":lang", lang).replace(
                            "*",
                            child.slugPath
                          )
                        )
                      }
                    >
                      {child.title}
                    </Typography>
                  </Stack>
                ))}
            </div>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocsPage;
