// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 20,
  H_DESKTOP: 20,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  WIDTH: 280,
};
