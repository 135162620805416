import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import { useResponsive } from "../../hooks/useResponsive";

import { NAV } from "../common/config-layouts";

// ----------------------------------------------------------------------

export default function Main({ children }) {
  const lgUp = useResponsive("up", "lg");

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
      }}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
