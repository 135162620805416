import authInterceptor from "../axios-configs/authInterceptor";
import { PRINTERS_URL } from "../urls";

export const getPrinterInfo = async (printerId) => {
  try {
    return authInterceptor.get(`${PRINTERS_URL}/${printerId}`);
  } catch (error) {
    throw error;
  }
};
