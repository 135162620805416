import landingInterceptor from "../axios-configs/landingInterceptor";
import { DOCS_TREE_URL } from "../urls";

export const getDocsTree = async () => {
  try {
    return landingInterceptor.get(DOCS_TREE_URL);
  } catch (error) {
    throw error;
  }
};
