import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { CloudContext } from "../../contexts/CloudContext";
import { useContext, useState, useEffect } from "react";
import { postCreateCloud } from "../../requests/clouds/postCreateCloud";
import Iconify from "../../components/Iconofy/Iconofy";
import cloudPlus from '../../assets/icons/cloud-plus.svg';
import Cloud from '../../assets/icons/cloud.svg';
import { CLOUD_URL, SETTINGS_URL, TOKENS_URL } from "../../navigation/routes";

const pages = [
  { name: "dashboard.home", path: CLOUD_URL },
  { name: "dashboard.tokens", path: TOKENS_URL },
  { name: "dashboard.settings", path: SETTINGS_URL },
];

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { clouds, setClouds, selectedCloud, selectCloud, open, setOpen } =
    useContext(CloudContext);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [cloudName, setCloudName] = useState("");
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [showAllert, setShowAllert] = useState(false);

  useEffect(() => {
    setIsCreateDisabled(cloudName.trim() === "");
  }, [cloudName]);

  const handleCloseDialog = () => {
    if (clouds.length > 0) {
      setOpen(false);
      setCloudName("");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleGoPage = (path) => {
    navigate(path);
  };

  const handleCreateCloud = async () => {
    console.log(cloudName);
    setIsCreateDisabled(true);
    try {
      const response = await postCreateCloud(cloudName);
      console.log(response.data);
      setClouds((prevClouds) => [...[response.data], ...prevClouds]);
      setIsAlertSuccess(true);
      setIsCreateDisabled(true);
    } catch (error) {
      console.log(error.data);
      setIsAlertSuccess(false);
    }
    setShowAllert(true);
    setTimeout(() => {
      setOpen(false);
      setCloudName("");
      setShowAllert(false);
    }, 1000);
  };

  const renderCloudSelect = () => {
    return (
      <FormControl
        sx={{ m: 1, minWidth: 200 }}
        variant="outlined"
        focused={false}
      >
        <Select
          value={selectedCloud ? selectedCloud?.name : clouds[0]?.name || ""}
          displayEmpty
          sx={{ bgcolor: "white" }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {clouds.map((cloud, index) => (
            <MenuItem
              value={cloud.name}
              key={index}
              onClick={() => selectCloud(cloud.name, cloud.id)}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Iconify icon={Cloud} />
                <Typography>{cloud.name}</Typography>
              </Stack>
            </MenuItem>
          ))}
          <Divider sx={{ my: 3 }} />
          <MenuItem value="create-cloud" onClick={() => setOpen(true)}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography> {t("dashboard.create-cloud")}</Typography>
              <Iconify icon={cloudPlus} />
            </Stack>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#3e403f" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              CloudPrintMe
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={index} onClick={() => handleGoPage(page.path)}>
                    <Typography textAlign="center">
                      {t(`${page.name}`)}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              CloudPrintMe
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, index) => (
                <Button
                  key={index}
                  onClick={() => handleGoPage(page.path)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {t(page.name)}
                </Button>
              ))}
            </Box>
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              {renderCloudSelect()}
            </Box>
          </Toolbar>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              mt: 1,
            }}
          >
            {renderCloudSelect()}
          </Box>
        </Container>
      </AppBar>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle sx={{ p: 2, mb: 1 }} variant="h4">
          {t("common.cloud-dialog-title")}
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={10}
            sx={{ p: 6 }}
          >
            <Grid item>
              <TextField
                name={t("common.cloud-dialog-name")}
                label={t("common.cloud-dialog-name")}
                value={cloudName}
                onChange={(e) => setCloudName(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                color="success"
                variant="contained"
                onClick={handleCreateCloud}
                disabled={isCreateDisabled}
              >
                {t("common.button-create")}
              </Button>
            </Grid>
          </Grid>
          {showAllert && (
            <Alert severity={isAlertSuccess ? "success" : "error"}>
              {isAlertSuccess
                ? t("dashboard.create-cloud-alert-success")
                : t("dashboard.create-cloud-alert-error")}
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
