import { Suspense } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import LandingLayout from "../layouts/landing/LandingLayout";
import DashboardLayout from "../layouts/dashboard/DashboardLayout";
import { FetchTriggerProvider } from "../contexts/FetchTriggerContext";
import { CloudProvider } from "../contexts/CloudContext";
import Loading from "../components/loading/Loading";

import { routes, landingRoutes } from "./nav.data";

import {
  LOGIN_URL,
  RESET_PASSWORD_URL,
  SIGNUP_URL,
  VERIFY_EMAIL_URL,
} from "./routes";

import {
  ErrorPage,
  LoginPage,
  ResetPassword,
  SignupPage,
  VerifyEmailPage,
} from "../pages";

const Router = () => {
  const router = createBrowserRouter([
    {
      element: (
        <CloudProvider>
          <FetchTriggerProvider>
            <DashboardLayout>
              <Suspense fallback={<Loading />}>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </FetchTriggerProvider>
        </CloudProvider>
      ),
      children: routes,
      errorElement: <ErrorPage />,
    },
    {
      element: (
        <LandingLayout>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </LandingLayout>
      ),
      children: landingRoutes,
      errorElement: <ErrorPage />,
    },
    {
      path: LOGIN_URL,
      element: <LoginPage />,
    },
    {
      path: SIGNUP_URL,
      element: (
        <GoogleReCaptchaProvider reCaptchaKey="6LdqQx8qAAAAAGQ1i6F5tTvUjt4xO0Ed5_bmgZsy">
          <SignupPage />
        </GoogleReCaptchaProvider>
      ),
    },
    {
      path: RESET_PASSWORD_URL,
      element: <ResetPassword />,
    },
    {
      path: VERIFY_EMAIL_URL,
      element: <VerifyEmailPage />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
