import landingInterceptor from "../axios-configs/landingInterceptor";
import { DOCS_URL } from "../urls";

export const getDoc = async (slugPuth) => {
  try {
    return landingInterceptor.get(`${DOCS_URL}/${slugPuth}`);
  } catch (error) {
    throw error;
  }
};
