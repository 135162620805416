import authInterceptor from "../axios-configs/authInterceptor";
import { PRINTERS_URL } from "../urls";

export const getPrinterPrintJobs = async (printerId, sortParam, page) => {
  try {
    return authInterceptor.get(
      `${PRINTERS_URL}/${printerId}/print-jobs?page=${page}&sort=${sortParam}&expand=computer,printer,printJobStates`
    );
  } catch (error) {
    console.log(error.data);
  }
};
