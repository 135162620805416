import { useContext, useState } from "react";
import PropTypes from "prop-types";

import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Link } from "@mui/material";

import Label from "../../../components/label/index.js";

import { useTranslation } from "react-i18next";
import Iconify from "../../../components/Iconofy/Iconofy.jsx";
import { deletePrintJob } from "../../../requests/index.js";
import DeleteDialog from "../../../components/delete-dialog/DeleteDialog.jsx";
import PrintJobInfoDialog from "./PrintJobInfoDialog.jsx";
import { printJobsStatusColors, printJobsStatusLabels } from "../../../helpers/printJobsStatuses.js";
import { convertTimeFromUnix } from "../../../helpers/convertTime.js";
import { FetchTriggerContext } from "../../../contexts/FetchTriggerContext.jsx";

import eye from '../../../assets/icons/eye-fill.svg';
import trash from '../../../assets/icons/trash.svg';
import more from '../../../assets/icons/more.svg';
import useErrorHandling from "../../../hooks/useErrorHandling.js";
// ----------------------------------------------------------------------



const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export function JobTableRow({
  selected,
  file_uri,
  id,
  created_at,
  expired_at,
  status,
  computer_name,
  printer_name,
  handleClick,
  print_statuses,
  setJobs,
}) {
  const { t } = useTranslation();

  const { setError } = useErrorHandling();

  const { fetchTrigger, setFetchTrigger } = useContext(FetchTriggerContext);

  const [openPopover, setOpenPopover] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialo] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const handleOpenMenu = (event) => setOpenPopover(event.currentTarget);

  const handleCloseMenu = () => setOpenPopover(null);

  const handleOpenDeleteDialog = () => setOpenDeleteDialo(true);

  const handleCloseDeleteDialog = () => setOpenDeleteDialo(false);

  const handleOpenInfoDialog = () => {
    handleCloseMenu();
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => setOpenInfoDialog(false);

  const deleteJob = async (print_id) => {
    try {
      const response = await deletePrintJob(print_id);
      console.log(response.data);
      setFetchTrigger(!fetchTrigger);
      handleCloseMenu();
      handleCloseDeleteDialog();
    } catch (error) {
      console.log(error.data);
      setError(error);
    }
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell component="th" scope="row" padding="none" align="center">
          <Typography variant="subtitle2" noWrap>
            {id}
          </Typography>
        </TableCell>

        <TableCell>
          <Link href={file_uri} target="_blank" rel="noopener noreferrer">
            {truncateString(file_uri, 80)}
          </Link>
        </TableCell>

        <TableCell align="center">
          <Label color={printJobsStatusColors[status]}>{t(printJobsStatusLabels[status])}</Label>
        </TableCell>

        <TableCell align="center">{convertTimeFromUnix(created_at)}</TableCell>

        <TableCell align="center">{convertTimeFromUnix(expired_at)}</TableCell>

        <TableCell align="right">
          <IconButton onClick={handleOpenMenu} disabled={selected}>
            <Iconify icon={more} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!openPopover}
        anchorEl={openPopover}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 170 },
        }}
      >
        <MenuItem onClick={handleOpenInfoDialog}>
          <Iconify icon={eye} sx={{ mr: 2 }} />
          {t("common.viewing")}
        </MenuItem>

        <MenuItem onClick={handleOpenDeleteDialog} sx={{ color: "error.main" }}>
          <Iconify icon={trash} sx={{ mr: 2 }} />
          {t("common.delete")}
        </MenuItem>
      </Popover>

      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        deleteFunc={() => deleteJob(id)}
      />

      <PrintJobInfoDialog
        open={openInfoDialog}
        onClose={handleCloseInfoDialog}
        file_uri={file_uri}
        id={id}
        created_at={created_at}
        expired_at={expired_at}
        status={status}
        computer_name={computer_name}
        printer_name={printer_name}
        print_statuses={print_statuses}
      />
    </>
  );
}

JobTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  company: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.number,
};
