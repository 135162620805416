import axios from "axios";
import { CLOUDS_URL } from "../urls";

export const deleteCloud = async (cloudId) => {
  try {
    return axios.delete(`${CLOUDS_URL}/${cloudId}`, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token")
      },
    });
  } catch (error) {
    throw error;
  }
};
