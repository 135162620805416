export const DEFAULT_URL = "/";

export const LANDING_URL = "/:lang";

export const DOCS_URL = "/:lang/docs";

export const DOC_URL = "/:lang/docs/*";

export const APP_URL = "/:lang/app";

export const CONTACT_URL = "/:lang/contact";

export const LOGIN_URL = "/:lang/login";

export const SIGNUP_URL = "/:lang/sign-up";

export const RESET_PASSWORD_URL = "/:lang/reset-password";

export const VERIFY_EMAIL_URL = "/:lang/verify-email";

export const CLOUD_URL = "/cloud";

export const COMPUTER_URL = "/computer/:computer_id";

export const PRINTER_URL = "/printer/:printer_id";

export const SETTINGS_URL = "/settings";

export const TOKENS_URL = "/tokens";
