import { useCallback, useEffect, useState } from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Label from "../../../components/label";
import { getUserInfo } from "../../../requests/me/getUserInfo";
import ContentLoader from "react-content-loader";
import useErrorHandling from "../../../hooks/useErrorHandling";

const BillingCard = () => {
  const { t } = useTranslation();
  const { setError } = useErrorHandling();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserInfo();
      console.log(response.data);
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error.data);
      setError(error);
    }
  }, [setError]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <Card sx={{ p: 3, cursor: "pointer" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6} lg={6} md={6}>
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ color: "text.disabled" }}>
              {t("cloud-page.billing")}
            </Typography>
            {loading ? (
              <ContentLoader speed={2} height={30} width={220}>
                <rect x="0" y="-3" rx="0" ry="0" width="220" height="30" />
              </ContentLoader>
            ) : (
              <Stack direction="row" spacing={1}>
                <Typography>{user.firstName}</Typography>
                <Typography>{user.lastName}</Typography>
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid xs={5} lg={2} md={2} item>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack alignItems="center" spacing={2}>
              <Typography variant="h6" sx={{ color: "text.disabled" }}>
                {t("cloud-page.balance")}
              </Typography>
              <Typography>0 EUR</Typography>
            </Stack>
            <Stack alignItems="center" spacing={2}>
              <Typography variant="h6" sx={{ color: "text.disabled" }}>
                {t("common.status")}
              </Typography>
              <Typography>
                <Label color="success">Активный</Label>
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BillingCard;
