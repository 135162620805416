import authInterceptor from "../axios-configs/authInterceptor";
import { COMPUTERS_URL } from "../urls";

export const getPrintersForComputer = async (computerId) => {
  try {
    return authInterceptor.get(
      `${COMPUTERS_URL}/${computerId}/printers?expand=printJobsCount`
    );
  } catch (error) {
    throw error;
  }
};
