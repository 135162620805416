import authInterceptor from "../axios-configs/authInterceptor";
import { PRINT_JOBS_URL } from "../urls";

export const deletePrintJob = async (jobId) => {
  try {
    return authInterceptor.delete(`${PRINT_JOBS_URL}/${jobId}`);
  } catch (error) {
    throw error;
  }
};
