import authInterceptor from "../axios-configs/authInterceptor";
import { COMPUTERS_URL } from "../urls";

export const getComputerInfo = async (computerId) => {
  try {
    return authInterceptor.get(`${COMPUTERS_URL}/${computerId}`);
  } catch (error) {
    throw error;
  }
};
