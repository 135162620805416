import authInterceptor from "../axios-configs/authInterceptor";
import { PRINTERS_URL } from "../urls";

export const deletePrinter = async (printer_id) => {
  try {
    return authInterceptor.delete(`${PRINTERS_URL}/${printer_id}`);
  } catch (error) {
    throw error;
  }
};
