import authInterceptor from "../axios-configs/authInterceptor";
import { TOKENS_URL } from "../urls";

export const deleteToken = async (id) => {
  try {
    return authInterceptor.delete(`${TOKENS_URL}/${id}`);
  } catch (error) {
    throw error;
  }
};
