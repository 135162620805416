import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import {
  DOCS_URL,
  APP_URL,
  CONTACT_URL,
  LOGIN_URL,
  SIGNUP_URL,
} from "../../navigation/routes";

const Header = () => {
  const { t } = useTranslation();

  const { lang } = useParams();

  const navigate = useNavigate();

  const pages = [
    {
      name: "landing-page.documentation",
      path: DOCS_URL.replace(":lang", lang),
    },
    { name: "landing-page.app", path: APP_URL.replace(":lang", lang) },
    { name: "landing-page.contact", path: CONTACT_URL.replace(":lang", lang) },
  ];

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleGoPage = (path) => {
    navigate(path);
  };

  return (
    <AppBar position="static" sx={{ background: "#edf6f7", boxShadow: "none", color: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            CloudPrintMe
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => handleGoPage(page.path)}>
                  <Typography textAlign="center" color= "black">
                    {t(`${page.name}`)}
                  </Typography>
                </MenuItem>
              ))}
              <Stack spacing={1} sx={{ m: 2 }}>
                <Button
                  onClick={() => navigate(LOGIN_URL.replace(":lang", lang))}
                  variant="contained"
                  color="success"
                >
                  {t("landing-page.login")}
                </Button>
                <Button
                  onClick={() => navigate(SIGNUP_URL.replace(":lang", lang))}
                  variant="contained"
                  color="primary"
                >
                  {t("landing-page.signup")}
                </Button>
              </Stack>
            </Menu>
          </Box>
          <Typography
            onClick={() => navigate("/")}
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            CloudPrintMe
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={() => handleGoPage(page.path)}
                sx={{ my: 2, color: "black", display: "block",  }}
              >
                {t(page.name)}
              </Button>
            ))}
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                onClick={() => navigate(LOGIN_URL.replace(":lang", lang))}
                variant="contained"
                color="success"
              >
                {t("landing-page.login")}
              </Button>
              <Button
                onClick={() => navigate(SIGNUP_URL.replace(":lang", lang))}
                variant="contained"
                color="primary"
              >
                {t("landing-page.signup")}
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
