import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteDialog = ({open, onClose, deleteFunc}) => {

  const {t} =  useTranslation();
  
  return(
    <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ p: 2 }} variant="h4">
          {t("common.delete-dialog-title")}
        </DialogTitle>
        <Divider sx={{ my: 2 }} />
        <DialogContent sx={{ p: 2 }}>
          <Typography>{t("common.delete-dialog-content")}</Typography>
        </DialogContent>
        <Divider sx={{ my: 2 }} />
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={onClose}>
            {t("common.button-no")}
          </Button>
          <Button variant="contained" color="warning" onClick={deleteFunc}>
            {t("common.button-yes")}
          </Button>
        </DialogActions>
      </Dialog>
  )

}

export default DeleteDialog;