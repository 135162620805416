import * as React from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { CLOUD_URL } from "../../navigation/routes";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
      cursor: "pointer",
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const CustomBreadcrumbs = ({
  loading,
  labels = [
    { name: "cloud", path: CLOUD_URL },
  ],
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return loading ? (
    <ContentLoader speed={2} height={24}>
      <rect x="0" y="0" rx="0" ry="0" width="600" height="24" />
    </ContentLoader>
  ) : (
    <Breadcrumbs aria-label="breadcrumb">
      <StyledBreadcrumb
        component="a"
        label={t("dashboard.home")}
        icon={<HomeIcon fontSize="small" />}
        onClick={() => navigate(CLOUD_URL)}
      />
      {labels.map((label, index) => (
        <StyledBreadcrumb
          key={index}
          component="a"
          label={label.name}
          disabled={labels.length === index + 1}
          onClick={() => navigate(`${label.path}`)}
        />
      ))}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
