import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TokensList from "./components/TokensList/TokensList";
import { useState } from "react";
import CreateTokenDialog from "./components/CreateTokenDialog";

const TokensPage = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} xl={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="h2">{t("tokens-page.title")}</Typography>
            <Button variant="contained" color="success" onClick={handleOpen}>
              <Typography variant="subtitle1">
                {t("tokens-page.button-create")}
              </Typography>
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <TokensList />
        </Grid>

        <CreateTokenDialog
          open={open}
          handleClose={handleClose}
        />

      </Grid>
    </Container>
  );
};

export default TokensPage;
