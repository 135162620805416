import { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { bgGradient } from "../../theme/css";
import Iconify from "../../components/Iconofy/Iconofy";
import { useTranslation } from "react-i18next";
import { postLogin } from "../../requests";
import { useNavigate } from "react-router-dom";
import eyeFill from "../../assets/icons/eye-fill.svg";
import eyeOffFill from "../../assets/icons/eye-off-fill.svg";
import { Alert } from "@mui/material";
import { CLOUD_URL, RESET_PASSWORD_URL, SIGNUP_URL } from "../../navigation/routes";
import { useRouteLang } from "../../hooks/useRouteLang";
import Head from "../../components/head/Head";

const LoginPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginButtonClicked, setIsLoginButtonClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState(null);
  const [unauthorizedAlert, setUnauthorizedAlert] = useState(false);

  const checkToken = useCallback(() => {
    if (localStorage.getItem("token")) {
      navigate(CLOUD_URL);
    }
  }, [navigate]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  useRouteLang();

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validateFields = () => {
    const newErrors = {};
    if (!email) newErrors.email = t("sign-up-page.empty-field");
    else if (!isValidEmail(email))
      newErrors.email = t("login-page.email-form-error");
    if (!password) newErrors.password = t("sign-up-page.empty-field");
    return newErrors;
  };

  const handleLogin = async () => {
    setIsLoginButtonClicked(true);
    setLoginError(null);
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoginButtonClicked(false);
      return;
    }

    setErrors({});

    try {
      const response = await postLogin(email, password);
      localStorage.setItem("token", response.data.token);
      console.log(response.data);
      navigate(CLOUD_URL);
    } catch (error) {
      setIsLoginButtonClicked(false);
      if (error.response && error.response.status === 422) {
        setLoginError(t("login-page.invalid-credentials"));
      } else if (
        error.response.data.password[0] === "LOGIN_ERROR_NOT_CONFIRMED"
      ) {
        console.log(error.data);
        setUnauthorizedAlert(true);
        setLoginError("LOGIN_ERROR_NOT_CONFIRMED");
      }
      console.log(error);
    }
  };

  const handleGoSignup = () => {
    navigate(SIGNUP_URL);
  };

  const handleGoResetPassword = () => {
    navigate(RESET_PASSWORD_URL);
  };

  const renderForm = (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t("login-page.email-form")}
          error={!!errors.email || !!loginError}
          helperText={
            errors.email || loginError ? errors.email || loginError : ""
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onClick={() => setIsLoginButtonClicked(false)}
        />

        <TextField
          name="password"
          label={t("login-page.password-form")}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onClick={() => setIsLoginButtonClicked(false)}
          error={!!errors.password || !!loginError}
          helperText={
            errors.password || loginError ? errors.password || loginError : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={handleGoResetPassword}
        >
          {t("login-page.form-subtitle")}
        </Link>
      </Stack>

      {unauthorizedAlert && <Alert severity="warning">{loginError}</Alert>}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleLogin}
        loading={isLoginButtonClicked}
      >
        {t("login-page.button")}
      </LoadingButton>
    </>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
        }),
        height: 1,
      }}
    >
      <Head title={"LOGIN"} description={"desc"} />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">{t("login-page.title")}</Typography>

          <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            {t("login-page.subtitle-1")}
            <Link
              variant="subtitle2"
              sx={{ ml: 0.5, cursor: "pointer" }}
              onClick={handleGoSignup}
            >
              {t("login-page.subtitle-2")}
            </Link>
          </Typography>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
};

export default LoginPage;
