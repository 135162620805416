import axios from "axios";
import { CLOUDS_URL } from "../urls";

export const getClouds = async () => {
  try {
    return axios.get(CLOUDS_URL, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token")
      },
    });
  } catch (error) {
    throw error;
  }
};
