import axios from "axios";

const landingInterceptor = axios.create();

landingInterceptor.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = localStorage.getItem("language");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default landingInterceptor;
