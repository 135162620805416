import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import languages from "../helpers/constans/languages";
import useErrorHandling from "./useErrorHandling";

export function useRouteLang() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { setError } = useErrorHandling();

  const { lang } = useParams();

  useEffect(() => {
    const languageKeys = languages.map((language) => language.key);
    const language = lang || "en";

    if (languageKeys.includes(language)) {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language);
    } else {
      setError({
        response: {
          data: {
            status: 404,
            statusText: "Not Found",
          },
        },
      });
    }
  }, [i18n, lang, navigate, setError]);
}
