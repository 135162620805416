import { Helmet } from "react-helmet-async";

const links = [
  { href: "https://cloudprint.me/en", lang: "en" },
  { href: "https://cloudprint.me/ru", lang: "ru" },
  { href: "https://cloudprint.me/de", lang: "de" },
  { href: "https://cloudprint.me/fr", lang: "fr" },
  { href: "https://cloudprint.me/it", lang: "it" },
  { href: "https://cloudprint.me/es", lang: "es" },
  { href: "https://cloudprint.me/pl", lang: "pl" },
];

const Head = ({ title, description }) => {
  const currentPath = window.location.pathname;
  const pathWithoutLang = currentPath.replace(/^\/(en|ru|de|fr|it|es|pl)/, "");

  return (
    <Helmet>
      {links.map(({ href, lang }) => (
        <link
          key={lang}
          rel="alternate"
          href={`${href}${pathWithoutLang}`}
          hreflang={lang}
        />
      ))}
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default Head;
