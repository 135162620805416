import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { getComputers } from "../../../requests";
import Label from "../../../components/label";
import {
  devicesStatusColors,
  devicesStatusLabels,
} from "../../../helpers/deviceStatuses";
import useErrorHandling from "../../../hooks/useErrorHandling";

const ComputersList = ({ id, computers, setComputers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setError } = useErrorHandling();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchComputers = useCallback(async () => {
    try {
      const response = await getComputers();
      setComputers(response.data.items);
      setLoading(false);
      if (response.data.items.length === 0) {
        setShowAlert(true);
      } else setShowAlert(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }, [setComputers, setError]);

  useEffect(() => {
    fetchComputers();
  }, [fetchComputers, id]);

  const handleNavigate = (id) => {
    navigate(`/computer/${id}`);
  };

  return (
    <>
      {loading ? (
        <Grid item xs={12} lg={4} md={5}>
          <Card sx={{ p: 3, minWidth: 300 }}>
            <ContentLoader
              speed={2}
              width={370}
              height={310}
              viewBox="0 0 370 310 "
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="10" rx="4" ry="3" width="250" height="30" />
              <rect x="260" y="10" rx="3" ry="3" width="50" height="30" />
              <rect x="0" y="50" rx="4" ry="3" width="100" height="30" />
              <rect x="0" y="150" rx="3" ry="3" width="300" height="30" />
              <rect x="0" y="200" rx="3" ry="3" width="300" height="30" />
              <rect x="0" y="250" rx="3" ry="3" width="300" height="30" />
            </ContentLoader>
          </Card>
        </Grid>
      ) : !showAlert ? (
        computers.map((computer) => (
          <Grid item xs={12} lg={4} md={5} key={computer.id}>
            <Card
              sx={{ p: 3, cursor: "pointer", minWidth: 300 }}
              onClick={() => handleNavigate(computer.id)}
            >
              <Stack spacing={2}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h5">{computer?.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Label color={devicesStatusColors[computer.status]}>
                      {t(devicesStatusLabels[computer.status])}
                    </Label>
                  </Grid>
                </Grid>

                <Typography variant="body">id {computer?.id}</Typography>

                <Divider sx={{ my: 3 }} />

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h6">{t("common.printers")}</Typography>
                  </Grid>
                  <Grid item>
                    <Label color="info">{computer.printersCount}</Label>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h6">
                      {t("common.print-jobs")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Label color="info">{computer.printJobsCount}</Label>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Button variant="contained" color="primary">
                  {t("common.button-go-to")} {computer?.name}
                </Button>
              </Stack>
            </Card>
          </Grid>
        ))
      ) : (
        <Alert severity="warning">
          {t("cloud-page.computers-title-not-found")}
        </Alert>
      )}
    </>
  );
};

export default ComputersList;
