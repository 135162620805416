import axios from "axios";
import { CLOUDS_URL } from "../urls";

export const postCreateCloud = async (cloudName) => {
  try {
    return axios.post(
      CLOUDS_URL,
      { name: cloudName },
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token")
        },
      }
    );
  } catch (error) {
    console.log(error.data);
  }
};
