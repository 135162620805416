export const acceptedFileTypes = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/gif": [".gif"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/x-iwork-pages-sffpages": [".pages"],
};
