import { useContext, useState } from "react";

import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

import TableCell from "@mui/material/TableCell";

import IconButton from "@mui/material/IconButton";
import { Stack, Typography } from "@mui/material";

import Label from "../../../../../components/label";

import Iconify from "../../../../../components/Iconofy/Iconofy.jsx";

import DeleteDialog from "../../../../../components/delete-dialog/DeleteDialog.jsx";

import { convertDataFromISO } from "../../../../../helpers/convertTime.js";
import { FetchTriggerContext } from "../../../../../contexts/FetchTriggerContext.jsx";

import trash from "../../../../../assets/icons/trash.svg";
import copy from "../../../../../assets/icons/file-copy.svg";
import done from "../../../../../assets/icons/done.svg";
import { deleteToken } from "../../../../../requests/index.js";
import useErrorHandling from "../../../../../hooks/useErrorHandling.js";
// ----------------------------------------------------------------------

export function TokenTableRow({
  id,
  selected,
  token,
  expired_at,
  handleClick,
}) {
  const { formattedDate, time, status } = convertDataFromISO(expired_at);

  const { setError } = useErrorHandling();

  const { fetchTrigger, setFetchTrigger } = useContext(FetchTriggerContext);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [copied, setCopied] = useState(false);

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteToken = async (id) => {
    try {
      const response = await deleteToken(id);
      console.log(response.data);
      setFetchTrigger(!fetchTrigger);
      handleCloseDeleteDialog();
    } catch (error) {
      console.log(error.data);
      setError(error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(token).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 7000);
    });
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell align="center">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography variant="body2" sx={{ textDecoration: "underline" }}>
              {token}
            </Typography>
            <IconButton onClick={handleCopy}>
              <Iconify icon={copied ? done : copy} />
            </IconButton>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Stack
            spacing={1}
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Label color={status}>{time}</Label>
            <Label color={status}>{formattedDate}</Label>
          </Stack>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={handleOpenDeleteDialog} disabled={selected}>
            <Iconify icon={trash} />
          </IconButton>
        </TableCell>
      </TableRow>

      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        deleteFunc={() => handleDeleteToken(id)}
      />
    </>
  );
}
