import authInterceptor from "../axios-configs/authInterceptor";
import { LOGOUT_URL } from "../urls";

export const postLogout = async () => {
  try {
    return authInterceptor.post(LOGOUT_URL, {});
  } catch (error) {
    throw error;
  }
};
