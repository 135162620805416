import React, { useState } from "react";
import { Box, Divider, Drawer, Fab, Toolbar } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DrawerList from "./DrawerList";

const drawerWidth = 240;

const DocsLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Toolbar />
        <Divider />
        <DrawerList onItemClick={handleDrawerToggle} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          width: 0.2,
          height: "100vh",
          flexShrink: 0,
          display: { xs: "none", md: "flex" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            position: "relative",
            background: "none"
          },
        }}
        open
      >
        <DrawerList onItemClick={handleDrawerToggle}  />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Fab
          color="extended"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            position: "fixed",
            bottom: 16,
            left: 16,
            display: { xs: "flex", md: "none" },
          }}
        >
          <MenuOpenIcon />
        </Fab>
        {children}
      </Box>
    </Box>
  );
};

export default DocsLayout;
