import {
  Alert,
  alpha,
  Box,
  Card,
  Checkbox,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { bgGradient } from "../../theme/css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postRequestPasswordReset, postResetPassword } from "../../requests";
import { isValidEmail, isValidPassword } from "../../helpers/isValidFields";
import { LOGIN_URL } from "../../navigation/routes";
import Head from "../../components/head/Head";

const ResetPassword = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [logoutAllDevices, setLogoutAllDevices] = useState(false);
  const [isLoginButtonClicked, setIsLoginButtonClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isShowEmailAlert, setIsShowEmailAlert] = useState(false);
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [lockButton, setLockButton] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setToken(queryParams.get("token"));
  }, [location.search]);

  const validateEmailField = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = t("sign-up-page.empty-field");

    } else if (!isValidEmail(email)) {
      newErrors.email = t("login-page.email-form-error");

    }
    return newErrors;
  };

  const validatePasswordField = () => {
    const newErrors = {};
    if (!password) newErrors.password = t("sign-up-page.empty-field");
    else if (!isValidPassword(password))
      newErrors.password = t("sign-up-page.invalid-password");
    return newErrors;
  };

  const handleSendEmail = async () => {
    setIsLoginButtonClicked(true);
    const validationErrors = validateEmailField();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoginButtonClicked(false);
      return;
    }

    setErrors({});

    try {
      const response = await postRequestPasswordReset(email);
      console.log(response.data);
      setIsShowEmailAlert(true);
      setIsLoginButtonClicked(false);
      setLockButton(true)
    } catch (error) {
      console.log(error.data);
      setIsLoginButtonClicked(false);
      setIsShowErrorAlert(true);
    }
  };

  const handleResetPassword = async () => {
    setIsLoginButtonClicked(true);
    const validationErrors = validatePasswordField();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoginButtonClicked(false);
      return;
    }

    setErrors({});

    try {
      const response = await postResetPassword(
        token,
        password,
        logoutAllDevices
      );
      console.log(response.data);
      navigate(LOGIN_URL);
    } catch (error) {
      console.log(error.data);
      setIsLoginButtonClicked(false);
      setIsShowErrorAlert(true);
    }
  };

  const handleSendEmailAgain = () => {};

  const handleLogoutAllDevices = () => setLogoutAllDevices(!logoutAllDevices);

  const passwordForm = (
    <Stack spacing={3}>
      <Typography variant="h6">{t("reset-password-page.password-title")}</Typography>

      <TextField
        name="password"
        label={t("login-page.password-form")}
        error={isPasswordTouched && !isValidPassword(password)}
        helperText={
          isPasswordTouched && !isValidPassword(password)
            ? t("sign-up-page.password-rules")
            : ""
        }
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setIsPasswordTouched(true);
        }}
        onBlur={() => setIsPasswordTouched(false)}
        onClick={() => setIsLoginButtonClicked(false)}
      />

      {isShowErrorAlert && (
        <Alert severity="error">{t("reset-password-page.error-alert")}</Alert>
      )}

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>{t("reset-password-page.sign-out")}</Typography>
        <Checkbox
          checked={logoutAllDevices}
          onChange={handleLogoutAllDevices}
          color="default"
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleResetPassword}
        loading={isLoginButtonClicked}
        disabled={!isValidPassword(password) || isLoginButtonClicked || lockButton}
      >
        {t("reset-password-page.reset-button")}
      </LoadingButton>
    </Stack>
  );

  const emailForm = (
    <Stack spacing={3}>
      <Typography variant="h6">{t("reset-password-page.email-title")}</Typography>

      {isShowEmailAlert && (
        <Alert severity="success">{t("reset-password-page.email-alert")}</Alert>
      )}

      <TextField
        name="email"
        label={t("login-page.email-form")}
        error={!!errors.email}
        helperText={errors.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onClick={() => setIsLoginButtonClicked(false)}
      />

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>{t("reset-password-page.didn't-receive")}</Typography>
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={handleSendEmailAgain}
        >
          {t("reset-password-page.send-again")}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleSendEmail}
        loading={isLoginButtonClicked}
        disabled={!isValidEmail(email) || isLoginButtonClicked || lockButton}
      >
        {t("reset-password-page.send-email")}
      </LoadingButton>
    </Stack>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
        }),
        height: 1,
      }}
    >
      <Head title={"SIGNUP"} description={"desc"} />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 520,
          }}
        >
          {token ? passwordForm : emailForm}
        </Card>
      </Stack>
    </Box>
  );
};

export default ResetPassword;
