const HOST = "https://api.cloudprint.me";
//------------------me-------------------
export const USER_URL = `${HOST}/me`;
//------------------login-------------------
export const LOGIN_URL = `${HOST}/login`;
//------------------signup-------------------
export const SIGNUP_URL = `${HOST}/signup`;
//------------------reset-password-------------------
export const REQUEST_PASSWORD_RESET_URL = `${HOST}/request-password-reset`;
export const RESET_PASSWORD_URL = `${HOST}/reset-password?token=`;
export const RESEND_VERIFICATION_EMAIL_URL = `${HOST}/resend-verification-email`;
//------------------verify-email-------------------
export const VERIFY_EMAIL_URL = `${HOST}/verify-email?token=`;
//------------------tokens-------------------
export const DOCS_URL = `${HOST}/docs`;
export const DOCS_TREE_URL = `${DOCS_URL}/tree`;
//------------------logout-------------------
export const LOGOUT_URL = `${HOST}/logout`;
//------------------clouds-------------------
export const CLOUDS_URL = `${HOST}/clouds`;
//------------------computers-------------------
export const COMPUTERS_URL = `${HOST}/computers`;//${computer_id}
export const CLOUD_COMPUTERS_URL = `${COMPUTERS_URL}?expand=printersCount,printJobsCount`;
//------------------print-jobs-------------------
export const PRINT_JOBS_URL = ` ${HOST}/print-jobs`;
export const POST_FILE_UPLOAD_URL = `${PRINT_JOBS_URL}/file-upload`;
//------------------printers-------------------
export const PRINTERS_URL = `${HOST}/printers`;
//------------------tokens-------------------
export const TOKENS_URL = `${HOST}/tokens`