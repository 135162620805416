import authInterceptor from "../axios-configs/authInterceptor";
import { USER_URL } from "../urls";

export const getUserInfo = async () => {
  try {
    return authInterceptor.get(USER_URL);
  } catch (error) {
    throw error;
  }
};
