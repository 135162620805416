import { createContext, useState } from "react";

export const FetchTriggerContext = createContext();

export const FetchTriggerProvider = ({ children }) => {
  const [fetchTrigger, setFetchTrigger] = useState(false);

  return (
    <FetchTriggerContext.Provider value={{ fetchTrigger, setFetchTrigger }}>
      {children}
    </FetchTriggerContext.Provider>
  );
};
