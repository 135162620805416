import React, { useState, useContext } from "react";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteCloud, deleteComputer, deletePrinter } from "../../requests";
import { CloudContext } from "../../contexts/CloudContext";
import DeleteDialog from "../../components/delete-dialog/DeleteDialog";
import PrintDialog from "./components/PrintDialog";
import { CLOUD_URL } from "../../navigation/routes";
import useErrorHandling from "../../hooks/useErrorHandling";

const ButtonsBar = ({
  cloud_id,
  computer_id,
  printer_id,
  computers,
  printers,
  setJobs
}) => {
  const { deleteCloudData } = useContext(CloudContext);

  const { setError } = useErrorHandling();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenPrintDialog = () => {
    setOpenPrintDialog(true);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDelete = async () => {
    try {
      let response;
      if (cloud_id) {
        response = await deleteCloud(cloud_id);
        handleCloseDeleteDialog();
        deleteCloudData(cloud_id);
      } else if (computer_id) {
        response = await deleteComputer(computer_id);
        handleCloseDeleteDialog();
        navigate(CLOUD_URL);
      } else if (printer_id) {
        response = await deletePrinter(printer_id);
        navigate(-1);
      }
      console.log(response);
    } catch (error) {
      console.log(error.data);
      setError(error);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Button
          variant="contained"
          color="success"
          onClick={handleOpenPrintDialog}
        >
          {t("common.button-print")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenDeleteDialog}
        >
          {t("common.button-delete")}
        </Button>
      </Stack>

      <PrintDialog
        cloud_id={cloud_id}
        open={openPrintDialog}
        setOpen={setOpenPrintDialog}
        computers={computers}
        printers={printers}
        setJobs={setJobs}
      />

      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        deleteFunc={handleDelete}
      />
    </>
  );
};

export default ButtonsBar;
