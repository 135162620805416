import axios from "axios";
import { SIGNUP_URL } from "../urls";

export const postSignup = async (
  firstName,
  lastName,
  email,
  password,
  reCaptcha
) => {
  try {
    return axios.post(
      SIGNUP_URL,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        reCaptcha: reCaptcha,
      },
      {}
    );
  } catch (error) {
    throw error;
  }
};
