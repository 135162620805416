const languages = [
  { key: "en", label: "EN", fullName: "English" },
  { key: "ru", label: "RU", fullName: "Русский" },
  { key: "de", label: "DE", fullName: "Deutsch" },
  { key: "fr", label: "FR", fullName: "Français" },
  { key: "it", label: "IT", fullName: "Italiano" },
  { key: "es", label: "ES", fullName: "Español" },
  { key: "pl", label: "PL", fullName: "Polski" },
];

export default languages;
