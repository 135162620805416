import axios from "axios";
import { RESET_PASSWORD_URL } from "../urls";

export const postResetPassword = async (token, password, logoutAllDevices) => {
  try {
    return axios.post(
      `${RESET_PASSWORD_URL}${token}`,
      { password: password, logoutAllDevices: logoutAllDevices },
      {}
    );
  } catch (error) {
    throw error;
  }
};
