import authInterceptor from "../axios-configs/authInterceptor";
import { COMPUTERS_URL } from "../urls";

export const getComputerPrintJobs = async (computerId, sortParam, page) => {
  try {
    return authInterceptor.get(
      `${COMPUTERS_URL}/${computerId}/print-jobs?page=${page}&sort=${sortParam}&expand=computer,printer,printJobStates`
    );
  } catch (error) {
    throw error;
  }
};
