import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TokenTableToolbar } from "./components/TokenTableToolbar";
import Scrollbar from "../../../../components/scrollbar";
import { TokenTableHead } from "./components/TokenTableHead";
import ContentLoader from "react-content-loader";
import { applyFilter, getComparator } from "../../../../helpers/tablesUtils";
import { TokenTableRow } from "./components/TokenTableRow";
import { getTokens } from "../../../../requests";
import { FetchTriggerContext } from "../../../../contexts/FetchTriggerContext";
import useErrorHandling from "../../../../hooks/useErrorHandling";

const TokensList = () => {
  const { t } = useTranslation();
  const { setError } = useErrorHandling();

  const { fetchTrigger } = useContext(FetchTriggerContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [orderBy, setOrderBy] = useState("-id");
  const [tokens, setTokens] = useState({});
  const [tokensArray, setTokensArray] = useState([]);
  const [tokensLoading, setTokensLoading] = useState(true);

  const gridRef = useRef(null);

  const fetchTokens = useCallback(
    async (sortField = "id", sortOrder = "asc", page = 0) => {
      try {
        const sortParam = `${sortOrder === "asc" ? "" : "-"}${sortField}`;
        const response = await getTokens(page + 1, sortParam);
        console.log(`jobs page ${page + 1}`, response.data);
        setTokens(response.data);
        setTokensArray(response.data.items);
        setTokensLoading(false);
      } catch (error) {
        console.log(error.data);
        setError(error);
      }
    },
    [setError]
  );

  useEffect(() => {
    fetchTokens(orderBy, order, page);
  }, [fetchTokens, order, orderBy, page, fetchTrigger]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleClearAllSelected = () => {
    setSelectAll(false);
    setSelected([]);
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(id);
    setTokensLoading(true);
    fetchTokens(id, isAsc ? "desc" : "asc", page);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectAll(true);
      const newSelecteds = tokensArray.map((p) => p.id);
      setSelected((prevSelected) => {
        const uniqueSelected = new Set([...prevSelected, ...newSelecteds]);
        return Array.from(uniqueSelected);
      });
      return;
    }
    setSelectAll(false);
    setSelected((prevSelected) => {
      const currentPageItems = tokensArray.map((p) => p.id);
      return prevSelected.filter((id) => !currentPageItems.includes(id));
    });
  };

  const handleChangePage = (event,newPage) => {
    setPage(newPage);
    setTokensLoading(true);
    if (gridRef.current) {
      window.scrollTo({
        top: gridRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    console.log("selected jobs", selected);
  };

  const dataFiltered = applyFilter({
    inputData: tokensArray,
    comparator: getComparator(order, orderBy),
  });

  return (
    <Grid item xs={12} md={12} lg={12} ref={gridRef}>
      <Stack spacing={3}>
        <Typography variant="body">
          {t("common.print-jobs-size")
            .replace(
              "{start}",
              `${
                tokensArray.length > 0 ? 1 + page * tokens?._meta?.perPage : 0
              }`
            )
            .replace(
              "{end}",
              Math.min(
                tokens?._meta?.totalCount,
                (page + 1) * tokens?._meta?.perPage
              ) || 0
            )
            .replace("{total}", tokens?._meta?.totalCount || 0)}
        </Typography>
      </Stack>
      <Card sx={{ mt: 3 }}>
        <TokenTableToolbar
          numSelected={selected.length}
          unSelectAll={handleClearAllSelected}
          filter={handleSort}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: "unset" }}>
            <Table sx={{ minWidth: 800 }}>
              <TokenTableHead
                order={order}
                orderBy={orderBy}
                rowCount={tokensArray.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                selectAll={selectAll}
                headLabel={[
                  { id: "token", label: "tokens-page.token", align: "center" },
                  {
                    id: "expired",
                    label: "common.expired-at",
                    align: "center",
                  },
                  { id: "", align: "center" },
                ]}
              />

              <TableBody>
                {tokensLoading
                  ? [...Array(10)].map((_, index) => (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell colSpan={8}>
                          <ContentLoader
                            speed={2}
                            height={50}
                            width="100%"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="100%"
                              height="50"
                            />
                          </ContentLoader>
                        </TableCell>
                      </TableRow>
                    ))
                  : dataFiltered.map((row) => (
                      <TokenTableRow
                        key={row.id}
                        id={row.id}
                        token={row.token}
                        expired_at={row.expired}
                        selected={selected.indexOf(row.id) !== -1}
                        handleClick={(event) => handleClick(event, row.id)}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          page={page}
          component="div"
          count={tokens?._meta?.totalCount || 0}
          rowsPerPage={tokens?._meta?.perPage || 0}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("common.of")} ${count}`
          }
          sx={{
            "& .MuiTablePagination-toolbar": {
              justifyContent: "flex-end",
            },
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-selectIcon":
              {
                display: "none",
              },
          }}
        />
      </Card>
    </Grid>
  );
};

export default TokensList;
