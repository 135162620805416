import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CardMedia, Container, Grid, Stack } from "@mui/material";
import Head from "../../components/head/Head";
import LandingBlock from "./components/LandingBlock";
import MainBlock from "./components/MainBlock";

import { DOCS_URL, SIGNUP_URL } from "../../navigation/routes";

import raw from "../../assets/landing/raw.png";
import printers from "../../assets/landing/printers.png";

const LandingPage = () => {
  const navigate = useNavigate();

  const { lang } = useParams();

  const { t } = useTranslation();

  return (
    <Container
      maxWidth="xl"
      sx={{
        py: 3,
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(15deg, transparent 50%, #edf6f7 50%)",
          zIndex: -1,
        },
      }}
    >
      <Head
        title={t("landing-page.head.title")}
        description={t("landing-page.head.description")}
      />

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <MainBlock />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <LandingBlock
            title={t("landing-page.cards.printers-title")}
            description={t("landing-page.cards.printers-desc")}
          >
            <CardMedia
              height="400"
              component="img"
              image={printers}
              title="main-printer"
              sx={{
                display: {
                  xs: "none",
                  lg: "block",
                  md: "block",
                },
              }}
            />
          </LandingBlock>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <LandingBlock
            title={t("landing-page.cards.codes-title")}
            description={t("landing-page.cards.codes-desc")}
            inversion
          >
            <CardMedia
              height="400"
              component="img"
              image={raw}
              title="main-printer"
              sx={{
                display: {
                  xs: "none",
                  lg: "block",
                  md: "block",
                },
              }}
            />
          </LandingBlock>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <LandingBlock
            title={t("landing-page.cards.start-title")}
            description={t("landing-page.cards.start-desc")}
            xsTitle={12}
            lgTitle={8}
            mdTitle={8}
            xsChildren={12}
            lgChildren={4}
            mdChildren={4}
          >
            <Stack direction="row" justifyContent="space-between" width="70">
              <Button
                onClick={() => navigate(SIGNUP_URL.replace(":lang", lang))}
                variant="contained"
                color="success"
                size="large"
              >
                {t("landing-page.get-start")}
              </Button>
              <Button
                onClick={() => navigate(DOCS_URL.replace(":lang", lang))}
                variant="contained"
                color="info"
                size="large"
              >
                {t("landing-page.learn-more")}
              </Button>
            </Stack>
          </LandingBlock>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
