import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader"
import LogoutDialog from "./components/LogoutDialog";
import { getUserInfo } from "../../requests/index"
import Iconify from "../../components/Iconofy/Iconofy";
import exit from "../../assets/icons/exit.svg";
import useErrorHandling from "../../hooks/useErrorHandling";
;

const SettingsPage = () => {
  const { t } = useTranslation();
  const { setError } = useErrorHandling();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [logoutDialog, setLogoutDialog] = useState(false);

  const handleOpenLogoutDialog = () => setLogoutDialog(true);
  const handleCloseLogoutDialog = () => setLogoutDialog(false);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserInfo();
      console.log(response.data);
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error.data);
      setError(error);
    }
  }, [setError]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={8} md={6}>
          <Card sx={{ p: 3 }}>
            <Stack></Stack>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4} md={6}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={4}>
              <Box>
                <Typography variant="h4">
                  {t("settings-page.account")}
                </Typography>
                <Divider />
              </Box>
              <Box>
                <Stack direction="row" spacing={1}>
                  <Typography variant="inherit" color="GrayText">
                    {t("settings-page.email")}
                  </Typography>
                  {loading ? (
                    <ContentLoader speed={2} height={21} width={220}>
                      <rect
                        x="0"
                        y="-3"
                        rx="0"
                        ry="0"
                        width="220"
                        height="25"
                      />
                    </ContentLoader>
                  ) : (
                    <Typography variant="subtitle1">{user.email}</Typography>
                  )}
                </Stack>
                <Divider />
              </Box>
              <Box>
                <Stack direction="row" spacing={1}>
                  <Typography variant="inherit" color="GrayText">
                    {t("settings-page.first-name")}
                  </Typography>
                  {loading ? (
                    <ContentLoader speed={2} height={21} width={300}>
                      <rect
                        x="0"
                        y="-3"
                        rx="0"
                        ry="0"
                        width="300"
                        height="25"
                      />
                    </ContentLoader>
                  ) : (
                    <Typography variant="subtitle1">
                      {user.firstName}
                    </Typography>
                  )}
                </Stack>
                <Divider />
              </Box>
              <Box>
                <Stack direction="row" spacing={1}>
                  <Typography variant="inherit" color="GrayText">
                    {t("settings-page.last-name")}
                  </Typography>
                  {loading ? (
                    <ContentLoader speed={2} height={21} width={230}>
                      <rect
                        x="0"
                        y="-3"
                        rx="0"
                        ry="0"
                        width="230"
                        height="25"
                      />
                    </ContentLoader>
                  ) : (
                    <Typography variant="subtitle1">{user.lastName}</Typography>
                  )}
                </Stack>

                <Divider />
              </Box>

              <Button
                color="error"
                variant="contained"
                onClick={handleOpenLogoutDialog}
                sx={{ marginTop: 4 }}
              >
                <Stack spacing={2} direction="row" alignItems="center">
                  <Typography>{t("settings-page.exit")}</Typography>
                  <Iconify icon={exit} />
                </Stack>
              </Button>
            </Stack>
          </Card>
        </Grid>

        <LogoutDialog open={logoutDialog} onClose={handleCloseLogoutDialog} />
      </Grid>
    </Container>
  );
};

export default SettingsPage;
