import { useCallback, useEffect, useState } from "react";
import { getPrintersForComputer } from "../../../requests";
import {
  Alert,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Label from "../../../components/label";

const statusColors = {
  0: "error",
  10: "success",
};

const statusLabels = {
  0: "common.innactive",
  10: "common.active",
};

const PrintersList = ({ id, printers, setPrinters, loading }) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);

  const fetchPrinters = useCallback(async () => {
    try {
      const response = await getPrintersForComputer(id);
      console.log("printers data", response.data);
      setPrinters(response.data.items);
      if(response.data.items.length === 0){
        setShowAlert(true);
      } setShowAlert(false);
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  }, [id, setPrinters]);

  useEffect(() => {
    fetchPrinters();
  }, [fetchPrinters]);

  const handleNavigate = (id) => {
    navigate(`/printer/${id}`);
  };

  return (
    <>
      {loading ? (
        <Grid item xs={12} lg={4} md={5}>
          <Card sx={{ p: 3, minWidth: 300 }}>
            <ContentLoader
              speed={2}
              width={370}
              height={250}
              viewBox="0 0 370 250 "
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="10" rx="4" ry="3" width="250" height="30" />
              <rect x="260" y="10" rx="3" ry="3" width="50" height="30" />
              <rect x="0" y="50" rx="4" ry="3" width="100" height="30" />
              <rect x="0" y="150" rx="3" ry="3" width="300" height="30" />
              <rect x="0" y="200" rx="3" ry="3" width="300" height="30" />
            </ContentLoader>
          </Card>
        </Grid>
      ) : !showAlert > 0 ? (
        printers.map((printer) => (
          <Grid item xs={12} lg={4} md={5} key={printer.id}>
            <Card
              sx={{ p: 3, cursor: "pointer", minWidth: 300 }}
              onClick={() => handleNavigate(printer.id)}
            >
              <Stack spacing={2}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h5">{printer?.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Label color={statusColors[printer.status]}>
                      {t(statusLabels[printer.status])}
                    </Label>
                  </Grid>
                </Grid>

                <Typography variant="body">id {printer?.id}</Typography>

                <Divider sx={{ my: 3 }} />

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h6">
                      {t("common.print-jobs")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Label color="info">{printer.printJobsCount}</Label>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Button variant="contained" color="primary">
                  {t("common.button-go-to")} {printer?.name}
                </Button>
              </Stack>
            </Card>
          </Grid>
        ))
      ) : (
        <Alert severity="warning">
          {t("computer-page.printers-title-not-found")}
        </Alert>
      )}
    </>
  );
};

export default PrintersList;
