import { useTheme } from "@emotion/react";
import {
  Alert,
  alpha,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/Iconofy/Iconofy";
import { LoadingButton } from "@mui/lab";
import { bgGradient } from "../../theme/css";
import { isValidEmail, isValidPassword } from "../../helpers/isValidFields";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import eyeFill from "../../assets/icons/eye-fill.svg";
import eyeOffFill from "../../assets/icons/eye-off-fill.svg";
import { postSignup } from "../../requests";
import { CLOUD_URL } from "../../navigation/routes";
import { useRouteLang } from "../../hooks/useRouteLang";
import Head from "../../components/head/Head";

const SignupPage = () => {

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [capVal, setCapVal] = useState(null);

  const [errors, setErrors] = useState({});

  const [isLoginButtonClicked, setIsLoginButtonClicked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isShowEmailAlert, setIsShowEmailAlert] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [lockButton, setLockButton] = useState(false);

  useRouteLang();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(CLOUD_URL);
    }
  }, [navigate]);

  const validateFields = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = t("sign-up-page.empty-field");
    if (!lastName) newErrors.lastName = t("sign-up-page.empty-field");
    if (!email) newErrors.email = t("sign-up-page.empty-field");
    else if (!isValidEmail(email))
      newErrors.email = t("login-page.email-form-error");
    if (!password) newErrors.password = t("sign-up-page.empty-field");
    else if (!isValidPassword(password))
      newErrors.password = t("sign-up-page.invalid-password");
    return newErrors;
  };

  const handleSignup = async () => {
    setIsLoginButtonClicked(true);
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoginButtonClicked(false);
      return;
    }
    setErrors({});
    try {
      const response = await postSignup(firstName, lastName, email, password, capVal);
      console.log(response.data);
      setIsShowEmailAlert(true);
      setLockButton(true);
      setIsLoginButtonClicked(false)
    } catch (error) {
      setIsLoginButtonClicked(false);
      console.log(error.data);
    }
  };


  const onVerify = useCallback((token) => {
    setCapVal(token);
  }, []);

  const renderForm = (
    <Stack spacing={3}>
      <TextField
        name="first name"
        label={t("sign-up-page.first-name")}
        error={!!errors.firstName}
        helperText={errors.firstName}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onClick={() => setIsLoginButtonClicked(false)}
      />

      <TextField
        name="last name"
        label={t("sign-up-page.last-name")}
        error={!!errors.lastName}
        helperText={errors.lastName}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onClick={() => setIsLoginButtonClicked(false)}
      />

      <TextField
        name="email"
        label={t("login-page.email-form")}
        error={!!errors.email}
        helperText={errors.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onClick={() => setIsLoginButtonClicked(false)}
      />

      <TextField
        name="password"
        label={t("login-page.password-form")}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setIsPasswordTouched(true);
        }}
        onBlur={() => setIsPasswordTouched(false)}
        onClick={() => setIsLoginButtonClicked(false)}
        error={isPasswordTouched && !isValidPassword(password)}
        helperText={
          isPasswordTouched && !isValidPassword(password)
            ? t("sign-up-page.password-rules")
            : ""
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                <Iconify icon={showPassword ? eyeFill : eyeOffFill} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleSignup}
        loading={isLoginButtonClicked}
        disabled={
          !firstName || !lastName || !email || !password || capVal === null || lockButton
        }
      >
        {t("sign-up-page.button")}
      </LoadingButton>

      {isShowEmailAlert && (
        <Alert severity="success">{t("reset-password-page.email-alert")}</Alert>
      )}
    </Stack>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
        }),
        height: 1,
      }}
    >
      <Head title={"SIGNUP"} description={"desc"} />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 500,
          }}
        >
          <Typography variant="h4">{t("sign-up-page.title")}</Typography>

          <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            {t("sign-up-page.subtitle")}
          </Typography>

          {renderForm}

          <GoogleReCaptcha onVerify={onVerify} />
        </Card>
      </Stack>
    </Box>
  );
};

export default SignupPage;
