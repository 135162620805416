import React, { lazy } from "react";
import DocsLayout from "../layouts/docs/DocsLayout";

import {
  APP_URL,
  CLOUD_URL,
  COMPUTER_URL,
  CONTACT_URL,
  DEFAULT_URL,
  DOC_URL,
  DOCS_URL,
  LANDING_URL,
  PRINTER_URL,
  SETTINGS_URL,
  TOKENS_URL,
} from "./routes";

const AppPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.AppPage }))
);
const CloudPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.CloudPage }))
);
const ComputerPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.ComputerPage }))
);
const ContactPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.ContactPage }))
);
const DocPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.DocPage }))
);
const DocsPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.DocsPage }))
);
const LandingPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.LandingPage }))
);
const PrinterPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.PrinterPage }))
);
const SettingsPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.SettingsPage }))
);
const TokensPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.TokensPage }))
);

export const routes = [
  {
    path: CLOUD_URL,
    element: <CloudPage />,
  },
  {
    path: COMPUTER_URL,
    element: <ComputerPage />,
  },
  {
    path: PRINTER_URL,
    element: <PrinterPage />,
  },
  {
    path: SETTINGS_URL,
    element: <SettingsPage />,
  },
  {
    path: TOKENS_URL,
    element: <TokensPage />,
  },
];

export const landingRoutes = [
  {
    path: DEFAULT_URL,
    element: <LandingPage />
  },
  {
    path: LANDING_URL,
    element: <LandingPage />,
  },
  {
    path: DOCS_URL,
    element: (
      <DocsLayout>
        <DocsPage />
      </DocsLayout>
    ),
  },
  {
    path: DOC_URL,
    element: (
      <DocsLayout>
        <DocPage />
      </DocsLayout>
    ),
  },
  {
    path: APP_URL,
    element: <AppPage />,
  },
  {
    path: CONTACT_URL,
    element: <ContactPage />,
  },
];
