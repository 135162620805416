import authInterceptor from "../axios-configs/authInterceptor";
import { PRINT_JOBS_URL } from "../urls";

export const postCreatePrintJob = async (printerId, uri) => {
  try {
    return authInterceptor.post(PRINT_JOBS_URL, {
      file_uri: uri,
      printerId: printerId,
    });
  } catch (error) {
    throw error;
  }
};
