import axios from "axios";
import { LOGIN_URL } from "../urls";

export const postLogin = async (email, password) => {
  try {
    return axios.post(
      LOGIN_URL,
      {
        email: email,
        password: password,
      }
    )
  } catch (error) {
    throw error;
  }

}