import { Container } from "@mui/material";
import Head from "../../components/head/Head";

const ContactPage = () => {
  return (
    <Container>
      <Head title={"CONTACT"} description={"desc"} />
    </Container>
  );
};

export default ContactPage;
