import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import Iconify from "../../../components/Iconofy/Iconofy.jsx";
import { useTranslation } from "react-i18next";

import trash from '../../../assets/icons/trash-fill.svg';
import broom from '../../../assets/icons/broom.svg';
import printer from '../../../assets/icons/printer.svg';
import filter_ic from '../../../assets/icons/filter.svg';

export const JobTableToolbar = ({
  numSelected,
  unSelectAll,
  filter,
}) => {
  const { t } = useTranslation();
  return (
    <Toolbar
      sx={{
        height: 96,
        display: "flex",
        justifyContent: "space-between",
        p: (theme) => theme.spacing(0, 1, 0, 3),
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t("common.selected")}{" "}
          <IconButton onClick={unSelectAll}>
            <Iconify icon={broom} />
          </IconButton>
        </Typography>
      ) : (
        <IconButton>
          <Iconify icon={printer} />
        </IconButton>
      )}
      {numSelected > 0 ? (
        <Tooltip title={t("common.delete")}>
          <IconButton>
            <Iconify icon={trash} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t("common.filter-list")} onClick={filter}>
          <IconButton>
            <Iconify icon={filter_ic} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

JobTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};
