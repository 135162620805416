import authInterceptor from "../axios-configs/authInterceptor";
import { CLOUD_COMPUTERS_URL } from "../urls";

export const getComputers = async () => {
  try {
    return authInterceptor.get(CLOUD_COMPUTERS_URL);
  } catch (error) {
    throw error;
  }
};
