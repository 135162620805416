import Box from "@mui/material/Box";
import Header from "./Header";
import Main from "./Main";
import { useRouteLang } from "../../hooks/useRouteLang";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useChangeLang } from "../../hooks/useChangeLang";
import Footer from "./Footer";

const LandingLayout = ({ children }) => {
  useRouteLang();
  useScrollToTop();
  useChangeLang();

  return (
    <>
      <Header />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Main>{children}</Main>
      </Box>

      <Footer />
    </>
  );
};

export default LandingLayout;
