import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CloudContext } from "../../contexts/CloudContext";
import ButtonsBar from "../../modules/ButtonsBar/ButtonsBar";
import { getComputerInfo, getPrinterInfo } from "../../requests";
import PrintJobsList from "../../modules/PrintJobsList/PrintJobsList";
import Label from "../../components/label";
import {
  devicesStatusColors,
  devicesStatusLabels,
} from "../../helpers/deviceStatuses";
import CustomBreadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import NotFoundAlert from "../../components/not-found-alert/NotFoundAlert";

const PrinterPage = () => {
  const { printer_id } = useParams();

  const { selectedCloud } = useContext(CloudContext);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const [printer, setPrinter] = useState([]);
  const [computer, setComputer] = useState([]);
  const [printJobs, setPrintJobs] = useState({});

  const fetchPrinter = useCallback(async () => {
    try {
      const response = await getPrinterInfo(printer_id);
      console.log("printer fetch", response.data);
      setPrinter([response.data]);
      fetchComputer(response.data.computerId);
      setShowAlert(false);
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  }, [printer_id]);

  const fetchComputer = async (computer_id) => {
    try {
      const response = await getComputerInfo(computer_id);
      setComputer([response.data]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrinter();
  }, [fetchPrinter, selectedCloud]);

  return (
    <Container>
      {showAlert ? (
        <NotFoundAlert
          text={t("printer-page.printer-not-found")
            .replace("{id}", printer_id)
            .replace("{cloudName}", localStorage.getItem("selectedCloudName"))}
        />
      ) : (
        <>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12} lg={12} md={12}>
              <CustomBreadcrumbs
                loading={loading}
                labels={[
                  { name: selectedCloud?.name, path: "/cloud" },
                  {
                    name: computer[0]?.name,
                    path: `/computer/${computer[0]?.id}`,
                  },
                  { name: printer[0]?.name, path: "" },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={9} md={10}>
              <Box display="flex" flexDirection="row">
                {loading ? (
                  <ContentLoader speed={2} height={90} width={400}>
                    <rect x="0" y="13" rx="0" ry="0" width="400" height="90" />
                  </ContentLoader>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={9} md={9}>
                      <Typography variant="h2">{printer[0].name}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                      <Stack mx={2} direction="row" spacing={1}>
                        <Typography variant="h6" color={"GrayText"}>
                          id {printer_id}
                        </Typography>
                        <Label color={devicesStatusColors[printer[0]?.status]}>
                          {t(devicesStatusLabels[printer[0]?.status])}
                        </Label>
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} lg={3} md={2} flexGrow={1}>
              <ButtonsBar
                computer_id={computer.id}
                printer_id={printer_id}
                computers={computer}
                printers={printer}
                setJobs={setPrintJobs}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Grid container sx={{ mt: 3 }}>
            <PrintJobsList
              printer_id={printer_id}
              jobs={printJobs}
              setJobs={setPrintJobs}
              loading={loading}
            />
          </Grid>
        </>
      )}
    </Container>
  );
};

export default PrinterPage;
