import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import languages from "../../helpers/constans/languages";
import { useLocation, useNavigate } from "react-router-dom";

const supportBlock = [
  { path: "/1", title: "Contact" },
  { path: "/2", title: "Applications" },
  { path: "/3", title: "Documentation" },
  { path: "/4", title: "For developers" },
];

const contuctBlock = [{ path: "", title: "email@mail.com" }];

const Footer = () => {
  const navigate = useNavigate();

  const path = useLocation();

  console.log(path);

  const handleLanguageChange = (langKey) => {
    const newPath = path.pathname.replace(/^\/[a-z]{2}/, `/${langKey}`);
    navigate(newPath);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "#3e403f",
        color: "white",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="xl">
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} lg={3} md={3}>
            <Stack alignItems="center" justifyContent="center" spacing={0.5}>
              <Typography variant="subtitle1" sx={{ color: "#FFA500" }}>
                LANGUAGES
              </Typography>
              {languages.map((lang) => (
                <Typography
                  key={lang.key}
                  variant="subtitle2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "rgba(255, 255, 255, 0.7)",
                    },
                  }}
                  onClick={() => handleLanguageChange(lang.key)}
                >
                  {lang.fullName}
                </Typography>
              ))}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            lg={3}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            <Stack alignItems="center" justifyContent="center" spacing={0.5}>
              <Typography variant="subtitle1" sx={{ color: "#FFA500" }}>
                SUPPORT
              </Typography>
              {supportBlock.map((el) => (
                <Typography
                  key={el.path}
                  variant="subtitle2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "rgba(255, 255, 255, 0.7)",
                    },
                  }}
                >
                  {el.title}
                </Typography>
              ))}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            lg={3}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            <Stack alignItems="center" justifyContent="center" spacing={0.5}>
              <Typography variant="subtitle1" sx={{ color: "#FFA500" }}>
                CONTACT US
              </Typography>
              {contuctBlock.map((el) => (
                <Typography
                  key={el.path}
                  variant="subtitle2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "rgba(255, 255, 255, 0.7)",
                    },
                  }}
                >
                  {el.title}
                </Typography>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} lg={3} md={3}>
            <Stack alignItems="center" justifyContent="center" spacing={0.5}>
              <Typography variant="h5">CloudPrintMe</Typography>
              <Typography variant="subtitle1">
                {`${new Date().getFullYear()} `}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
