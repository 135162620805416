import authInterceptor from "../axios-configs/authInterceptor";
import { PRINT_JOBS_URL } from "../urls";

export const getCloudPrintJobs = async (sortParam, page) => {
  try {
    return authInterceptor.get(
      `${PRINT_JOBS_URL}?page=${page}&sort=${sortParam}&expand=computer,printer,printJobStates`
    );
  } catch (error) {
    throw error;
  }
};
