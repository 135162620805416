import {
  Button,
  Card,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DOCS_URL, SIGNUP_URL } from "../../../navigation/routes";
import main from "../../../assets/landing/main.png";

const MainBlock = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { lang } = useParams();

  return (
    <Card
      sx={{
        p: 5,
        width: 0.9,
        background: "none",
        boxShadow: "none",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} lg={6} md={6}>
          <Stack spacing={3} alignItems="center">
            <Stack spacing={2}>
              <Typography variant="h3">
                {t("landing-page.lending-title")}
              </Typography>
              <Typography variant="body1">
                {t("landing-page.lending-desc")}
              </Typography>
            </Stack>
            <Stack spacing={2} width={200}>
              <Button
                onClick={() => navigate(SIGNUP_URL.replace(":lang", lang))}
                variant="contained"
                color="success"
              >
                {t("landing-page.get-start")}
              </Button>
              <Button
                onClick={() => navigate(DOCS_URL.replace(":lang", lang))}
                variant="contained"
                color="info"
              >
                {t("landing-page.learn-more")}
              </Button>
              <Button
                onClick={() => navigate(DOCS_URL.replace(":lang", lang))}
                variant="contained"
                color="secondary"
              >
                {t("landing-page.use-cases")}
              </Button>
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sx={{
            display: {
              xs: "none",
              lg: "block",
              md: "block",
            },
          }}
        >
          <CardMedia
            height="500"
            component="img"
            image={main}
            title="main-printer"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MainBlock;
