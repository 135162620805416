import { Container } from "@mui/material";
import Head from "../../components/head/Head";

const AppPage = () => {

  return(
    <Container>
      <Head title={"APP"} description={"APPdesc"} />
    </Container>
  )

}

export default AppPage;