import { Card, Grid, Stack, Typography } from "@mui/material";

const LandingBlock = ({
  children,
  title,
  description,
  inversion = false,
  xsTitle = 12,
  lgTitle = 6,
  mdTitle = 6,
  xsChildren = 12,
  lgChildren = 6,
  mdChildren = 6,
}) => {
  return (
    <Card
      sx={{
        p: 5,
        width: 0.9,
        background: "none",
        boxShadow: "none",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {inversion ? (
          <>
            <Grid item xs={xsChildren} lg={lgChildren} md={mdChildren}>
              {children}
            </Grid>
            <Grid item xs={xsTitle} lg={lgTitle} md={mdTitle}>
              <Stack spacing={2}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body1">{description}</Typography>
              </Stack>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={xsTitle} lg={lgTitle} md={mdTitle}>
              <Stack spacing={2}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body1">{description}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={xsChildren} lg={lgChildren} md={mdChildren}>
              {children}
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default LandingBlock;
