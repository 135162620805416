import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { createRef, useCallback, useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import Label from "../../../components/label";
import { POST_FILE_UPLOAD_URL } from "../../../requests/urls";
import { postCreatePrintJob } from "../../../requests/print-jobs/postCreatePrintJob";
import { getPrintersForComputer } from "../../../requests";
import { FetchTriggerContext } from "../../../contexts/FetchTriggerContext";
import { acceptedFileTypes } from "../../../helpers/acceptedFileTypes";

const PrintDialog = ({
  open,
  setOpen,
  onClose,
  computers,
  printers = [],
}) => {
  const { t } = useTranslation();

  const dropzoneRef = createRef();

  const { fetchTrigger, setFetchTrigger } = useContext(FetchTriggerContext);

  const [files, setFiles] = useState([]);
  const [fileUris, setFileUris] = useState([]);
  const [printersArray, setPrintersArray] = useState(printers);

  const [isPrintEnabled, setIsPrintEnabled] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  const [selectedComputerName, setSelectedComputerName] = useState("");
  const [selectedPrinterName, setSelectedPrinterName] = useState("");

  const [selectedComputerId, setSelectedComputerId] = useState(null);
  const [selectedPrinterId, setSelectedPrinterId] = useState(null);

  const fetchPrinters = useCallback(
    async (computerId) => {
      try {
        const response = await getPrintersForComputer(computerId);
        setPrintersArray(response.data.items);
      } catch (error) {
        console.log(error);
      }
    },
    [setPrintersArray]
  );

  useEffect(() => {
    if (printers.length === 1) {
      setSelectedPrinterName(printers[0].name);
      setSelectedPrinterId(printers[0].id);
    }
  }, [printers]);

  useEffect(() => {
    if (computers.length === 1) {
      setSelectedComputerName(computers[0].name);
      setSelectedComputerId(computers[0].id);
      fetchPrinters(computers[0].id);
    } else {
      setPrintersArray([]);
    }
  }, [fetchPrinters, computers]);

  const handleClosePrintDialog = () => {
    if (!computers.length === 1 && !printers.length === 1) {
      setSelectedComputerId(null);
      setSelectedPrinterId(null);
      setSelectedComputerName("");
      setSelectedPrinterName("");
      setPrintersArray([]);
    }
    setOpen(false);
    setFiles([]);
    setFileUris([]);
    setIsPrintEnabled(false);
    setUploadProgress({});
  };

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    acceptedFiles.forEach((file) => handleSendFile(file));
  };

  const handleSendFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log("yolo 1",formData);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", POST_FILE_UPLOAD_URL);

    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    xhr.setRequestHeader("cloud", localStorage.getItem("selectedCloudId"));

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.floor((event.loaded / event.total) * 100);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: progress,
        }));
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const data = JSON.parse(xhr.responseText);
        console.log("yolo",data);
        setFileUris((prevUris) => [...prevUris, data.uri]);
        if (selectedPrinterId) {
          setIsPrintEnabled(true);
        }
      } else {
        console.error(
          "Error uploading file",
          JSON.parse(xhr.responseText),
          xhr.status
        );
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          print: "common.files-not-uploaded",
          type: "error",
        }));
      }
    };

    xhr.send(formData);
  };

  const handlePrint = async () => {
    try {
      setIsPrintEnabled(false);
      const printPromises = fileUris.map((uri) =>
        postCreatePrintJob(selectedPrinterId, uri).then((response) => {
          console.log("Print job response:", response);
          setFetchTrigger(!fetchTrigger);
          return response;
        })
      );

      let completedPrints = 0;
      printPromises.forEach((promise) =>
        promise.then(() => {
          completedPrints += 1;
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            print: `${completedPrints}/${fileUris.length} files`,
          }));
        })
      );

      console.log(printPromises);

      await Promise.all(printPromises);

      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        print: "common.files-uploaded",
        type: "success",
      }));


      setTimeout(() => {
        handleClosePrintDialog();
      }, 1000);
    } catch (error) {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        print: "common.files-not-uploaded",
        type: "error",
      }));
      console.error("Error sending print request:", error);
    }
  };

  const handleChangeComputer = (event) => {
    setSelectedComputerName(event.target.value);
  };

  const handleChangePrinter = (event) => {
    setSelectedPrinterName(event.target.value);
    if (fileUris.length > 0) {
      setIsPrintEnabled(true);
    }
  };

  const handleSelectComputer = (id) => {
    setSelectedComputerId(id);
    fetchPrinters(id);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }} variant="h4">
        {t("common.print-dialog-title")}
      </DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 2, mb: 3 }}
          spacing={5}
        >
          <FormControl fullWidth disabled={computers.length === 1 || computers.length === 0}>
            <InputLabel id="computer-select-label">
              {t("common.computer")}
            </InputLabel>
            <Select
              labelId="computer-select-label"
              id="computer-select"
              value={selectedComputerName}
              label={t("common.computer")}
              onChange={handleChangeComputer}
            >
              {computers.map((comp) => (
                <MenuItem
                  value={comp.name}
                  key={comp.id}
                  onClick={() => handleSelectComputer(comp.id)}
                >
                  {comp.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            disabled={
              !selectedComputerId ||
              printersArray.filter(
                (printer) => printer.computerId === selectedComputerId
              ).length === 0 ||
              printers.length === 1
            }
          >
            <InputLabel id="printer-select-label">
              {t("common.printer")}
            </InputLabel>
            <Select
              labelId="printer-select-label"
              id="printer-select"
              value={selectedPrinterName}
              label={t("common.printer")}
              onChange={handleChangePrinter}
            >
              {printersArray.map((printer) => (
                <MenuItem
                  value={printer.name}
                  key={printer.id}
                  onClick={() => setSelectedPrinterId(printer.id)}
                >
                  {printer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Dropzone
          ref={dropzoneRef}
          onDrop={onDrop}
          noClick
          noKeyboard
          accept={acceptedFileTypes}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                border: "2px dashed #ccc",
                padding: "50px",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <p>{t("common.drop-desc")}</p>
              <Button
                variant="outlined"
                color="success"
                onClick={() => dropzoneRef.current.open()}
                disabled={!selectedPrinterId}
              >
                {t("common.print-dialog-desc")}
              </Button>
            </div>
          )}
        </Dropzone>
        {files.length > 0 && (
          <>
            <Stack sx={{ mt: 2 }}>
              <Typography variant="h4">{t("common.files")}:</Typography>
              <ul>
                {files.map((file, index) => (
                  <Stack key={index}>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Stack alignItems="center" direction="row" spacing={4}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {file.name}
                        </Typography>
                        <Label color="success" sx={{ p: 1, fontSize: "15px" }}>
                          {uploadProgress[file.name] || 0}%
                        </Label>
                      </Stack>
                    </li>
                    <Divider sx={{ my: 2 }} />
                  </Stack>
                ))}
              </ul>
              {uploadProgress.print && (
                <Alert severity={uploadProgress.type}>
                  {t(uploadProgress.print)}
                </Alert>
              )}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleClosePrintDialog} color="error">
          {t("common.cancel")}
        </Button>
        <Button
          onClick={handlePrint}
          color="success"
          variant="contained"
          disabled={!isPrintEnabled}
        >
          {t("common.button-print")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintDialog;
