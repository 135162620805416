import { createContext, useState, useEffect, useCallback } from "react";
import { getClouds } from "../requests";
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import { LOGIN_URL, VERIFY_EMAIL_URL } from "../navigation/routes";

export const CloudContext = createContext();

export const CloudProvider = ({ children }) => {
  const navigate = useNavigate();

  const [clouds, setClouds] = useState([]);
  const [cloudLoading, setCloudLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [selectedCloud, setSelectedCloud] = useState({
    name: "",
    id: null,
  });

  const fetchClouds = useCallback(async () => {
    if (localStorage.getItem("token")) {
      try {
        const response = await getClouds();
        if (response.data.items.length === 0) {
          navigate(VERIFY_EMAIL_URL.replace(':lang', localStorage.getItem("language")));
        }
        setClouds(response.data.items);
        if (
          localStorage.getItem("selectedCloudName") &&
          localStorage.getItem("selectedCloudId")
        ) {
          selectCloud(
            localStorage.getItem("selectedCloudName"),
            localStorage.getItem("selectedCloudId")
          );
        } else if (response.data.items.length > 0) {
          selectCloud(response.data.items[0].name, response.data.items[0].id);
        }
        setCloudLoading(false);
      } catch (error) {
        console.log(error.data);
        localStorage.removeItem("token");
        navigate(LOGIN_URL.replace(':lang', localStorage.getItem("language")));
      }
    } else {
      navigate(LOGIN_URL);
      setCloudLoading(false);
    }
  }, [navigate]);

  const selectCloud = (cloudName, cloudId) => {
    setSelectedCloud({
      name: cloudName,
      id: cloudId,
    });
    localStorage.setItem("selectedCloudName", cloudName);
    localStorage.setItem("selectedCloudId", cloudId);
  };

  const deleteCloudData = (cloudId) => {
    const updatedClouds = clouds.filter(
      (cloud) => cloud.id !== Number(cloudId)
    );
    console.log(
      "clouds after delete",
      updatedClouds,
      "cloud id",
      typeof cloudId
    );

    setClouds(updatedClouds);

    // selectCloud(updatedClouds[0].name, updatedClouds[0].id);

    if (updatedClouds.length > 0) {
      selectCloud(updatedClouds[0].name, updatedClouds[0].id);
    } else {
      localStorage.removeItem("selectedCloudName");
      localStorage.removeItem("selectedCloudId");
      setSelectedCloud({ name: "", id: null });
      setOpen(true);
      navigate(VERIFY_EMAIL_URL.replace(':lang', localStorage.getItem("language")));
    }
  };

  useEffect(() => {
    fetchClouds();
  }, [fetchClouds]);

  if (cloudLoading) {
    return <Loading />;
  }

  return (
    <CloudContext.Provider
      value={{
        clouds,
        setClouds,
        selectedCloud,
        selectCloud,
        cloudLoading,
        deleteCloudData,
        open,
        setOpen,
      }}
    >
      {children}
    </CloudContext.Provider>
  );
};
