import { useEffect } from "react";
import i18n from "../i18n";
import { useLocation, useNavigate } from "react-router-dom";

export function useChangeLang() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let lang = localStorage.getItem("language");

    if (!lang) {
      lang = navigator.language.slice(0, 2);
    }
    
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);

    if (location.pathname === "/") {
      navigate(`/${lang}`);
    }
  }, [navigate, location]);
}
