import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDoc } from "../../requests";
import { Container, Grid, Typography } from "@mui/material";
import useErrorHandling from "../../hooks/useErrorHandling";
import Head from "../../components/head/Head";
import { convertTimeFromUnix } from "../../helpers/convertTime";

const DocPage = () => {
  const { "*": slugPath } = useParams();

  const { setError } = useErrorHandling();

  const [doc, setDoc] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await getDoc(slugPath);
      setDoc(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }, [slugPath, setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      <Head title={doc.title} description={doc.description} />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12}>
          <Typography variant="h2">{doc.title}</Typography>
        </Grid>

        <Grid item xs={12} lg={12} md={12}>
          <Typography variant="body1" style={{ color: "gray" }}>
            {convertTimeFromUnix(doc.updatedAt || 0, "dateOnly")}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={12} md={12}>
          <div dangerouslySetInnerHTML={{ __html: doc.content }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocPage;
