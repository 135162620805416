import axios from "axios";
import { REQUEST_PASSWORD_RESET_URL } from "../urls";

export const postRequestPasswordReset = async (email) => {
  try {
    return axios.post(
      REQUEST_PASSWORD_RESET_URL,
      {
        email: email,
      },
      {}
    );
  } catch (error) {
    throw error;
  }
};
