import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";
import { getComputerInfo } from "../../requests";
import PrintersList from "./components/PrintersList";
import { useTranslation } from "react-i18next";
import { CloudContext } from "../../contexts/CloudContext";
import ButtonsBar from "../../modules/ButtonsBar/ButtonsBar";
import PrintJobsList from "../../modules/PrintJobsList/PrintJobsList";
import Label from "../../components/label";
import {
  devicesStatusColors,
  devicesStatusLabels,
} from "../../helpers/deviceStatuses";
import CustomBreadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import NotFoundAlert from "../../components/not-found-alert/NotFoundAlert";

const ComputerPage = () => {
  const { computer_id } = useParams();

  const { selectedCloud } = useContext(CloudContext);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const [computer, setComputer] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [printJobs, setPrintJobs] = useState({});

  const fetchComputer = useCallback(async () => {
    try {
      const response = await getComputerInfo(computer_id);
      setComputer([response.data]);
      setShowAlert(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  }, [computer_id]);

  useEffect(() => {
    fetchComputer();
  }, [fetchComputer, selectedCloud]);

  return (
    <Container>
      {showAlert ? (
        <NotFoundAlert
          text={t("computer-page.computer-not-found")
            .replace("{id}", computer_id)
            .replace("{cloudName}", localStorage.getItem("selectedCloudName"))}
        />
      ) : (
        <>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12} lg={9} md={10}>
              <CustomBreadcrumbs
                loading={loading}
                labels={[
                  { name: selectedCloud?.name, path: "/cloud" },
                  { name: computer[0]?.name, path: "" },
                ]}
              />
            </Grid>

            <Grid item xs={12} lg={9} md={10}>
              <Box display="flex" flexDirection="row">
                {loading ? (
                  <ContentLoader speed={2} height={65} width={600}>
                    <rect x="0" y="17" rx="0" ry="0" width="600" height="65" />
                  </ContentLoader>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={9} md={9}>
                      <Typography variant="h2">{computer[0].name}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                      <Stack spacing={2} direction="row">
                        <Typography variant="h6" color={"GrayText"}>
                          id {computer_id}
                        </Typography>
                        <Label color={devicesStatusColors[computer[0].status]}>
                          {t(devicesStatusLabels[computer[0].status])}
                        </Label>
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} lg={3} md={2} flexGrow={1}>
              <ButtonsBar
                computer_id={computer_id}
                computers={computer}
                printers={printers}
                setJobs={setPrintJobs}
              />
            </Grid>
          </Grid>

          <Typography variant="h4" sx={{ mt: 3 }}>
            {t("common.printers")}
          </Typography>

          <Grid container sx={{ mt: 3 }} spacing={2}>
            <PrintersList
              loading={loading}
              id={computer_id}
              printers={printers}
              setPrinters={setPrinters}
            />
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Grid container sx={{ mt: 3 }}>
            <PrintJobsList
              loading={loading}
              computer_id={computer_id}
              jobs={printJobs}
              setJobs={setPrintJobs}
            />
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ComputerPage;
