import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FetchTriggerContext } from "../../../contexts/FetchTriggerContext";
import { createToken } from "../../../requests";
import useErrorHandling from "../../../hooks/useErrorHandling";

const CreateTokenDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();

  const { fetchTrigger, setFetchTrigger } = useContext(FetchTriggerContext);

  const { setError } = useErrorHandling();

  const handleCreateToken = async () => {
    try {
      await createToken();
      setFetchTrigger(!fetchTrigger);
      handleClose();
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ p: 2 }} variant="h4">
        {t("tokens-page.create-dialog-title")}
      </DialogTitle>
      <Divider sx={{ my: 2 }} />
      <DialogContent sx={{ p: 2 }}>
        <Typography>{t("tokens-page.create-dialog-desc")}</Typography>
      </DialogContent>
      <Divider sx={{ my: 2 }} />
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          {t("common.button-no")}
        </Button>
        <Button variant="contained" color="success" onClick={handleCreateToken}>
          {t("common.button-yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTokenDialog;
