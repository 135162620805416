import authInterceptor from "../axios-configs/authInterceptor";
import { TOKENS_URL } from "../urls";

export const getTokens = async (page, sortParam) => {
  try {
    return authInterceptor.get(`${TOKENS_URL}?expand=id&page=${page}&sort=${sortParam}`);
  } catch (error) {
    throw error;
  }
};
