import ThemeProvider from "../src/theme";
import Router from "./navigation/Router";

function App() {
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}

export default App;
