export const printJobsStatusColors = {
  0: "info",
  1: "primary",
  2: "success",
  4: "warning",
  5: "warning",
  6: "error",
  7: "error",
};

export const printJobsStatusLabels = {
  0: "common.statuses.0",
  1: "common.statuses.1",
  2: "common.statuses.2",
  4: "common.statuses.3",
  5: "common.statuses.4",
  6: "common.statuses.5",
  7: "common.statuses.6",
};