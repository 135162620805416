import { Alert, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CLOUD_URL } from "../../navigation/routes";

const NotFoundAlert = ({ text }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Alert
      severity="error"
      direction="row"
      alignitems="center"
      action={
        <Button
          variant="outlined"
          color="error"
          onClick={() => navigate(CLOUD_URL)}
        >
          {t("dashboard.home")}
        </Button>
      }
    >
      <Typography>{text}</Typography>
    </Alert>
  );
};

export default NotFoundAlert;
