import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { postLogout } from "../../../requests";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../../../navigation/routes";

const LogoutDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await postLogout();
      localStorage.removeItem("token");
      localStorage.removeItem("selectedCloudId");
      localStorage.removeItem("selectedCloudName");
      navigate(LOGIN_URL);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }} variant="h4">
        {t("common.delete-dialog-title")}
      </DialogTitle>
      <Divider sx={{ my: 2 }} />
      <DialogContent sx={{ p: 2 }}>
        <Typography>{t("settings-page.logout-dialog")}</Typography>
      </DialogContent>
      <Divider sx={{ my: 2 }} />
      <DialogActions>
        <Button variant="outlined" color="warning" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="error" onClick={handleLogout}>
          {t("common.button-yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
