import {
  Alert,
  alpha,
  Box,
  Card,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { bgGradient } from "../../theme/css";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { postCreateCloud, postVerifyEmail } from "../../requests";
import { CLOUD_URL, LOGIN_URL } from "../../navigation/routes";
import Head from "../../components/head/Head";

const VerifyEmailPage = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [cloudName, setCloudName] = useState("Cloud1");
  const [isLoginButtonClicked, setIsLoginButtonClicked] = useState(false);
  const [showVerifyEmailAlert, setShowVerifyEmailAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const location = useLocation();

  const verifyEmail = useCallback(
    async (token) => {
      try {
        const response = await postVerifyEmail(token);
        console.log(response.data);
        localStorage.setItem("token", response.data.token);
        setShowVerifyEmailAlert(true);
      } catch (error) {
        console.log(error);
        setShowErrorAlert(true);
        navigate(LOGIN_URL);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      const queryParams = new URLSearchParams(location.search);
      setToken(queryParams.get("token"));
      verifyEmail(queryParams.get("token"));
    }
  }, [location.search, verifyEmail, navigate]);

  const handleCreateCloud = async () => {
    console.log(cloudName);
    try {
      const response = await postCreateCloud(cloudName);
      console.log(response.data);
      navigate(CLOUD_URL);
    } catch (error) {
      console.log(error.data);
    }
  };

  const renderForm = (
    <Stack spacing={3}>
      {token && showVerifyEmailAlert && (
        <Alert severity="success">
          {t("verify-email-page.verify-success")}
        </Alert>
      )}

      {token && showErrorAlert && (
        <Alert severity="error">
          {t("verify-email-page.verify-unsuccess")}
        </Alert>
      )}

      <Typography variant="h4">{t("verify-email-page.title")}</Typography>

      <TextField
        name="cloud name"
        label={t("sign-up-page.first-name")}
        error={cloudName === ""}
        helperText={cloudName === "" && t("sign-up-page.empty-field")}
        value={cloudName}
        onChange={(e) => setCloudName(e.target.value)}
        onClick={() => setIsLoginButtonClicked(false)}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleCreateCloud}
        loading={isLoginButtonClicked}
        disabled={!cloudName}
      >
        {t("common.cloud-dialog-title")}
      </LoadingButton>
    </Stack>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
        }),
        height: 1,
      }}
    >
      <Head title={"SIGNUP"} description={"desc"} />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 500,
          }}
        >
          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
};

export default VerifyEmailPage;
