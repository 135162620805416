import authInterceptor from "../axios-configs/authInterceptor";
import { COMPUTERS_URL } from "../urls";

export const deleteComputer = async (id) => {
  try {
    return authInterceptor.delete(`${COMPUTERS_URL}/${id}`);
  } catch (error) {
    throw error;
  }
};
