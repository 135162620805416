import { format } from "date-fns";

export const convertTimeFromUnix = (timestamp, type) => {
  const date = new Date(timestamp * 1000);
  if (type === "dateOnly") {
    return format(date, "dd.MM.yyyy");
  }
  return format(date, "dd.MM.yyyy HH:mm");
};

export const convertTimeFromMillisecond = (timestamp) => {
  if (timestamp === undefined) {
    return "";
  }

  const date = new Date(timestamp);
  return format(date, "dd.MM.yyyy HH:mm:ss.SSS");
};

export const convertDataFromISO = (isoString) => {
  const date = new Date(isoString);
  const now = new Date();
  const formattedDate = date.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Получение времени в формате hh:mm:ss
  const time = date.toTimeString().split(" ")[0];

  // Проверка, истекло ли время
  const status = date < now ? "error" : "success";

  return {
    formattedDate,
    time,
    status,
  };
};
