import { useContext, useState } from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { CloudContext } from "../../contexts/CloudContext";
import ComputersList from "./components/ComputersList";
import PrintJobsList from "../../modules/PrintJobsList/PrintJobsList";
import ButtonsBar from "../../modules/ButtonsBar/ButtonsBar";
import BillingCard from "./components/BillingCard";
import CustomBreadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const CloudPage = () => {
  const { selectedCloud, cloudLoading } = useContext(CloudContext);

  const [printsJobs, setPrintJobs] = useState({});
  const [computers, setComputers] = useState([]);

  const { t } = useTranslation();

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} lg={12} md={612}>
          <CustomBreadcrumbs
            labels={[{ name: selectedCloud.name, path: "/cloud" }]}
          />
        </Grid>

        <Grid item xs={12} lg={6} md={6}>
          <Box display="flex" flexDirection="row">
            {cloudLoading ? (
              <ContentLoader speed={2} height={70}>
                <rect x="0" y="13" rx="0" ry="0" width="400" height="70" />
              </ContentLoader>
            ) : (
              <>
                <Typography variant="h2">{selectedCloud.name}</Typography>
                <Box mx={2}>
                  <Typography variant="h6" color={"GrayText"}>
                    id {selectedCloud.id}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} lg={6} md={6} flexGrow={1}>
          <ButtonsBar
            cloud_id={selectedCloud.id}
            computers={computers}
            setJobs={setPrintJobs}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12} md={12} sx={{ mt: 3 }}>
        <BillingCard />
      </Grid>

      <Typography variant="h4" sx={{ mt: 3 }}>
        {t("common.computers-title")}
      </Typography>

      <Grid container sx={{ mt: 3 }} spacing={2}>
        <ComputersList
          id={selectedCloud.id}
          computers={computers}
          setComputers={setComputers}
        />
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Grid container sx={{ mt: 3 }}>
        <PrintJobsList
          cloud_id={selectedCloud.id}
          jobs={printsJobs}
          setJobs={setPrintJobs}
        />
      </Grid>
    </Container>
  );
};

export default CloudPage;
